import {Controller} from "@hotwired/stimulus"

const AUTO_COMPLETE_CHANGE_EVENT = 'autocomplete.change'
const disableClass = 'pointer-events-none'

const activeClass = ['bg-gradient-to-r', 'from-primary-400', 'to-primary-500']


export default class extends Controller {
    static targets = ["githubAutocomplete", "githubResult", "selectedUser"]

    static values = {username: String}

    showSelectedUser() {
        this.selectedUserTarget.innerText = this.usernameValue
        this.githubResultTarget.removeAttribute('hidden')
    }

    autoCompleteListener = (event) => {
        const {value} = event.detail
        console.log({value})
        if (value) {
            this.usernameValue = value
            this.showSelectedUser()
        }
    }

    onInput = () => {
        this.githubSubmitTarget.classList.remove(...activeClass)
        this.githubSubmitTarget.classList.add(disableClass)
        this.githubSubmitTarget.setAttribute('aria-disabled', true)
    }

    initialize() {
        console.log(this.usernameValue, "user selected")
        this.githubAutocompleteTarget.addEventListener(AUTO_COMPLETE_CHANGE_EVENT, this.autoCompleteListener)
    }

    disconnect() {
        this.githubAutocompleteTarget.removeEventListeners(AUTO_COMPLETE_CHANGE_EVENT, this.autoCompleteListener)
    }

}